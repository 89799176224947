// 引入api
import { fun_PostAction, fun_GetBlob } from "@/api";
import { fun_MeetingType, fun_UserState } from "@/utils/validate.js";
import axios from "axios";

// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      ids: [],
      // 查询表单
      formSearchList: [
        // {
        //   type: "input",
        //   label: "参会者类型：",
        //   value: "userRole",
        //   placeholder: "请选择"
        //   // queryParams: {
        //   //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
        //   //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
        //   // }
        // },
        // {
        //   type: "input",
        //   label: "参会者角色：",
        //   value: "userRole",
        //   placeholder: "请选择"
        //   // queryParams: {
        //   //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
        //   //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
        //   // }
        // }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "批量下载",
          type: "primary",
          icon: "document-add"
        }
      ],
      // 表格基础数据
      tableColumns: [
        /* {
          label: "项目名称",
          prop: "projectName",
          width: "300px",
          fixed: true
        }, */
        {
          label: "参会者姓名",
          prop: "userRealName",
          width: "300px",
          fixed: true
        },
        {
          label: "参会者身份证号",
          prop: "userIdCard",
          width: "200px",
        },
        {
          label: "参会者状态",
          prop: "userState",
          width: "100px",
        },
        {
          label: "参会者电脑CPU情况",
          prop: "computerCpu",
          width: "150px"
        },
        {
          label: "网卡地址",
          prop: "networkCards",
          width: "200px",
        },
        {
          label: "硬盘序列号",
          prop: "hardDiskSn",
          width: "200px",
        },
        {
          label: "导入时间",
          prop: "importDate",
          width: "200px",
        },
        /* {
          label: "会议类型",
          prop: "meetingType",
          width: "200px",
        }, */

        {
          label: "文件上传的起始时间",
          prop: "fileUploadTime",
          width: "200px",
        },
        {
          label: "操作",
          slotName: "cz",
          width: "200px",
          fixed: "right"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10, // 每页条数
      },
      total: 0, // 总条数
      multipleSelection: []
    };
  },
  // prop
  props: {
    id: ""
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();

  },
  // 自定义方法
  methods: {
    /* 
    * 下载
    */
    fun_Download(e) {
      let fileName = e.data.userRealName;
      let fileUrl = e.data.fileUrl;
      // 接口
      /*       let res = fun_GetBlob("monitoringRecord/batchDownLoad", {
              id:e.data.id
            });
   
             // 创建标签
             let link = document.createElement('a');
             // 创建blob
             let blob = new Blob([res], { type: 'application/mp4' });
             // 设置标签不可见
             link.style.display = 'none';
             // 设置标签href属性
             link.href = URL.createObjectURL(blob);
             // 设置标签download属性
             link.setAttribute('download', fileName+`.mp4`);
             // 标签添加到body
             document.body.appendChild(link);
             // 执行点击动作
             link.click();
             // 删除
             document.body.removeChild(link); */



      axios({
        method: 'get',
        url: fileUrl,
        responseType: 'blob'  // 二进制流文件
      }).then(res => {
        // 创建标签
        let link = document.createElement('a');
        // 创建blob
        let blob = new Blob([res.data], { type: 'application/mp4' });
        // 设置标签不可见
        link.style.display = 'none';
        // 设置标签href属性
        link.href = URL.createObjectURL(blob);
        // 设置标签download属性
        link.setAttribute('download', fileName + `.mp4`);
        // 标签添加到body
        document.body.appendChild(link);
        // 执行点击动作
        link.click();
        // 删除
        document.body.removeChild(link);
      }).catch(req => {
        // 请求失败，提示信息
        this.$message.error(req);
        // console.log('error' + req);
      })
    },

    fun_Detail(e) {
      // 子传父
      this.$emit('fun_LookDetails', e);

    },
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      // let res = await fun_PostAction("meetingUserInfo/list", {
      let res = await fun_PostAction("monitoringRecord/list", {
        projectId: this.id,
        ...this.reqPageData
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      if (result) {
        // 解构data
        const { total, records } = data;
        // 循环数据
        records.map((e) => {
          // 转化 会议类型，参会者状态
          e.meetingType = fun_MeetingType(e.meetingType);
          e.userState = fun_UserState(e.userState);
        })
        // 情况当前页 列表id集合
        this.ids = [];
        // 回显table
        this.tableData = records;
        // 筛选ids 集合
        this.tableData.map((e) => {
          this.ids.push(e.id);
        })
        // 获取分页总条数
        this.total = total;
        // 关闭loading
        this.loading = false;
      } else {
        // 请求失败，弹出失败msg的消息提示
        this.$message.error("失败");
      }

    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /* 
    * 按钮组
    */
    async  fun_click() {
      // 打开loading
      this.loading = true;
      //  测试id  ,'7d1667db-c37c-46d0-ad03-c953375b6183'
      console.log(this.ids);
      let res = await fun_GetBlob("monitoringRecord/batchDownLoad", {
        // id: ['179d440b-bf4d-4f10-ba5c-852bc7b3d5a0']
        id: this.ids
      });
      // 解构res.data
      const { statusText, data } = res;
      if (statusText == "OK") {
        // 创建blob 处理数据
        let blob = new Blob([data], { type: 'application/zip' });
        // 创建下载链接
        let url = window.URL.createObjectURL(blob);
        // 创建标签
        let link = document.createElement('a');
        // 设置标签不可见
        link.style.display = 'none';
        // 设置标签href属性
        link.href = url;
        // 设置标签download属性
        link.setAttribute('download', `test.zip`);
        // a标签添加到body
        document.body.appendChild(link);
        // 执行点击动作
        link.click();
        // 删除a标签
        document.body.removeChild(link);
        // 释放blob
        window.URL.revokeObjectURL(url);

      } else {
        // 请求失败，弹出失败msg的消息提示
        this.$message.error("失败");
      }
    },


    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    }
  }
};
